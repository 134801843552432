<template>
  <div style="margin-left: 2%;">
    <div >
      <div class="d-flex pb-4">
        <div class="ticketTextHeading">Tickets sold information</div>
        <div class="ticketTextLine"></div>&nbsp;&nbsp;
      <h4  class="ticketTextHeading1" v-if="this.allseats">({{this.totalonlinesold}}/{{this.allseats}})</h4>
         <h4  class="ticketTextHeading1" v-else>({{this.totalonlinesold}}/0)</h4>
      </div>
      <div>
        <h5>Ticket URL:&nbsp;<a :href="pretixRedirectUrl" target="_blank" style="margin-top: 1%">{{
          this.pretixRedirectUrl
        }}</a> </h5>
      </div>
      <div>
        <h5>Facebook Event URL:&nbsp;<a :href="facebookUrl" target="_blank" style="margin-top: 1%">{{
          this.facebookUrl
        }}</a> </h5>
      </div>
      <div v-if="this.boostedonFlag">
        <h5>Boosted On Facebook:&nbsp;<span v-if="this.boosted_on_fb" style="color:green">YES</span>
        <span v-else style="color:red">NO</span></h5>
      </div>
      <br>
      <v-spacer></v-spacer>
       <v-btn
        class="text-capitalize px-5"
        style="border-radius: 10px"
        dark
        color="#2C1963"
        height="44"
        :loading="loading"
           @click="getTicketList()"
    >
        <span>Update Ticket Info</span>
      </v-btn>
    <!--  <v-btn
        class="text-capitalize px-5"
        style="border-radius: 10px"
        dark
        color="#2C1963"
        height="44"
        @click="toggleTicketInfoModal({ show: true })"
      v-if="this.adminAccess==='Super Admin'||this.adminAccess=='Sales Representative' ||this.adminAccess=='Event Support Staff' ">
        <span>Update Info</span>
      </v-btn>-->
    </div>
    <br>
    <v-simple-table class="elevation-8" style="margin-top: 2%">
      <template v-slot:default>
        <thead>
          <tr style="background: #e7e5ed">
          
            <th style="width: 80px"></th>
            <th
              class="textHeader text-center"
              v-for="(item, i) in items"
              :key="i"
            >
              {{ item.ticket_type_name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width: 80px">
              <div>Online</div>
            </td>
            <td class="text-center" v-for="(item, i) in items" :key="i">
              {{ item.total_online_ticket_sold }}
            </td>
          </tr>
        <!--  <tr>
            <td class="text-center" style="width: 80px">
              <div>Paper</div>
            </td>
            <td class="text-center" v-for="(item, i) in items" :key="i">
              {{ item.total_offline_ticket_sold }}
            </td>
          </tr>-->
        </tbody>
        <!-- <tfoot>
          <tr>
            <td style="width: 50%">
              <div class="d-flex px-4">
                <v-icon small color="#067605" v-if="profit"
                  >mdi-arrow-up</v-icon
                >
                <v-icon small color="#D21140" v-else> mdi-arrow-down</v-icon>
                <div
                  style="width: 100%"
                  class="footerStyle"
                  :style="profit ? 'color:#067605' : 'color:#D21140'"
                >
                  {{ profit_amount }}%(You have sold {{ profit_amount }}% more
                  tickets in comparison to last year)
                </div>
              </div>
            </td>
            <td style="width: 50%"></td>
          </tr>
        </tfoot> -->
      </template>
    </v-simple-table>
    <br>
    
   <!--    <div class="d-flex pt-3 px-4">
      <v-icon small color="#067605" v-if="profit">mdi-arrow-up</v-icon>
      <v-icon small color="#D21140" v-else> mdi-arrow-down</v-icon>
      <div
        class="footerStyle"
        :style="profit ? 'color:#067605' : 'color:#D21140'"
      >
        {{ profit_amount }}%(You have sold {{ profit_amount }}% more tickets in
        comparison to last year)
      </div>
      <v-spacer></v-spacer>
   <div class="footerStyle" style="font-size:12px">
      Last update by {{last_updateBy}} {{last_updatedDate}} at {{last_updatedTime}}
      </div>
    </div>-->
    <ticket-info-modal @reload="getTicketInfo"></ticket-info-modal>
    <br /><br /><br /><br />
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { GAME_TICKET_INFO_DETAIL_API,ADMIN_TICKET_TYPE_LIST } from "@/constants/APIUrls";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TicketInfoTable",
  components: {
    TicketInfoModal: () => import("./AddTicketInfoModal.vue"),
  },
  data() {
    return {
     adminAccess:localStorage.getItem("adminAccess"),
      items: [],
      profit: false,
      profit_amount: null,
      last_updateBy: "",
      last_updatedDate: "",
      last_updatedTime: "",
      totalonlinesold:"",
       pretixRedirectUrl: "",
       facebookUrl:"",
       boosted_on_fb:false,
       boostedonFlag:false,
        loading: false,
      allseats:"",
      headers: [
        {
          sortable: false,
          text: "",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Students",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "General admission",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Court side plus",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Reserved",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Benchwarmer",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleTicketInfoModal: "gameManagement/toggleTicketInfo",
    }),
    getTicketInfo() {
      const successHandler = (res) => {
        this.items = res.data.ticket_detail;
        this.profit = res.data.profit_flag;
        this.profit_amount = res.data.ticket_profit;
        this.last_updateBy = res.data.last_updated_by_name;
        this.last_updatedDate = res.data.last_updated_on.date;
        this.last_updatedTime = res.data.last_updated_on.time;
        this.totalonlinesold=res.data.total_sold_online;
          this.pretixRedirectUrl = res.data.pretix_redirect_url;
          this.facebookUrl=res.data.facebook_url;
          this.boosted_on_fb=res.data.boosted_on_fb;
           this.boostedonFlag=true;
        if(res.data.all_seats){
        this.allseats=res.data.all_seats
        }
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.$emit("reload");
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_TICKET_INFO_DETAIL_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
      getTicketList() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.ticket_list;
        this.loading = false;
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id
      Axios.request_GET(
        ADMIN_TICKET_TYPE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    //   this.getTicketInfo()
  },
  created() {
    this.$root.$refs.TicketInfoTable = this;
  },
};
</script>
<style scoped>
.textHeader {
  font-size: 14px;
}
.footerDetail {
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  /* or 22px */
}
.ticketTextHeading1 {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
.ticketTextLine {
  width: 59px;
  height: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  background: #2c1963;
  border-radius: 23px;
}
.footerStyle {
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
}
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
</style>
