<style scoped>
.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  width: 16.1em;
  height: 2.5em;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
.ticketTextLine {
  width: 59px;
  height: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  background: #2c1963;
  border-radius: 23px;
}
</style>
<template>
  <div>
    <br />
    <div class="d-flex pb-4">
      <div class="ticketTextHeading" style="margin-left: 10px">
        Things to do
      </div>
      <div class="ticketTextLine"></div>
    </div>
    <br />
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="selectedHeaders.header"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <div class="pt-5">
          <v-tabs
            v-model="selected"
            grow
            mobile-breakpoint
            background-color="transparent"
            color="red"
            class="px-20"
          >
            <v-tab
              v-for="(item, i) in tabs"
              :key="i"
              class="text-capitalize px-6"
              v-text="item.name"
              style="margin-left: 40px"
            ></v-tab>
          </v-tabs>
          <b><hr style="margin-bottom: 5px" /></b>
        </div>
        <div class="d-flex justify-end pa-4 pt-2">
          <v-btn
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px"
            v-if="tabsSelectedValue === 'voucher'"
            @click="createVoucher()"
            >Bulk Voucher</v-btn
          >&nbsp;&nbsp;
          <v-btn
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px"
            @click="openModal"
          >
            <span>{{ selectedHeaders.text }}</span>
          </v-btn>
        </div>
      </template>
      <template v-slot:top>
        <div class="pt-5">
          <v-tabs
            v-model="selected"
            grow
            mobile-breakpoint
            background-color="transparent"
            color="red"
            class="px-20"
          >
            <v-tab
              v-for="(item, i) in tabs"
              :key="i"
              class="text-capitalize px-6"
              v-text="item.name"
              style="margin-left: 40px"
            ></v-tab>
          </v-tabs>
          <b><hr style="margin-bottom: 5px" /></b>
        </div>
      </template>
      <template v-slot:item="props" v-if="tabsSelectedValue === 'assembly'">
        <tr style="height: 84px">
          <td
            class="text-center"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <div class="tableText">{{ props.item.school_name }}</div>
            <div style="font-size: 12px; color: #757575">
              {{ props.item.school }}
            </div>
          </td>
          <td class="text-center" v-else>
            <div class="tableText">{{ props.item.school_name }}</div>
            <div style="font-size: 12px; color: #757575">
              {{ props.item.school }}
            </div>
          </td>
          <td
            class="text-center"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <div class="tableText">
              {{ props.item.assemble_date_2 }},{{ props.item.start_time_str }}
            </div>
          </td>
          <td class="text-center" v-else>
            <div class="tableText">
              {{ props.item.assemble_date_2 }},{{ props.item.start_time_str }}
            </div>
          </td>
          <td
            class="text-center py-2"
            v-if="props.item.school == null"
            style="background-color: #ffa8b5"
          >
            <div class="tableText">
              {{ props.item.name }}
            </div>
            <div style="font-size: 12px; color: #757575">
              <div>{{ props.item.phone }}</div>
              <div>{{ props.item.email }}</div>
            </div>
          </td>
          <td class="text-center py-2" v-else>
            <div class="tableText">
              {{ props.item.name }}
            </div>
            <div style="font-size: 12px; color: #757575">
              <div>{{ props.item.phone }}</div>
              <div>{{ props.item.email }}</div>
            </div>
          </td>
          <td
            class="text-center"
            v-if="props.item.school == null "
            style="background-color: #ffa8b5"
          >
           <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="
                border-radius: 10px;
                margin-left: 15px;
                font-size: 20px;
              "
           v-if="$vuetify.breakpoint.smAndUp && props.item.user_type!='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="
                border-radius: 10px;
                margin-left: 15px;
                font-size: 20px;
              "
           v-if="$vuetify.breakpoint.smAndUp && props.item.threshold_hours>=24 && props.item.user_type=='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
               @click="toggleMessageModal({ show: true})"
              height="45px"
              style="
                border-radius: 10px;
                margin-left: 15px;
                font-size: 20px;
              "
           v-if="$vuetify.breakpoint.smAndUp && props.item.threshold_hours<24 && props.item.user_type=='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
             <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
           v-if="$vuetify.breakpoint.xsOnly && props.item.user_type!='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
             <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
           v-if="$vuetify.breakpoint.xsOnly && props.item.threshold_hours>=24 && props.item.user_type=='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
              <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
             @click="toggleMessageModal({ show: true})"
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
           v-if="$vuetify.breakpoint.xsOnly && props.item.threshold_hours<24 && props.item.user_type=='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
           v-if="props.item.user_type!='game'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
          <td class="text-center" v-else>
           <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="
                border-radius: 10px;
                margin-left: 15px;
                font-size: 20px;
              "
             v-if="$vuetify.breakpoint.smAndUp &&  props.item.user_type!='game'" >
             
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="45px"
              style="
                border-radius: 10px;
                margin-left: 15px;
                font-size: 20px;
              "
             v-if="$vuetify.breakpoint.smAndUp && props.item.threshold_hours>=24 && props.item.user_type=='game'" >
             
              <span>Update Booked Assembly</span>
            </v-btn>
              <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="toggleMessageModal({ show: true})"
              height="45px"
              style="
                border-radius: 10px;
                margin-left: 15px;
                font-size: 20px;
              "
             v-if="$vuetify.breakpoint.smAndUp && props.item.threshold_hours<24 && props.item.user_type=='game'" >
             
              <span>Update Booked Assembly</span>
            </v-btn>
             <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
           v-if="$vuetify.breakpoint.xsOnly &&  props.item.user_type!='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
             <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="
                toggleAssemblyModal1({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
           v-if="$vuetify.breakpoint.xsOnly && props.item.threshold_hours>=24 && props.item.user_type=='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
              <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              @click="toggleMessageModal({ show: true})"
              height="24px"
              style="
                border-radius: 10px;
                margin-left: 10px;
                width: 60%;
                font-size: 8px;
              "
           v-if="$vuetify.breakpoint.xsOnly && props.item.threshold_hours<24 && props.item.user_type=='game'" >
              <span>Update Booked Assembly</span>
            </v-btn>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
            v-if="props.item.user_type!='game'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'hotel' ">
        <tr>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.name }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.hotel_address }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.confirmation_number }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.checking_in_date_2 }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.checking_out_date_2 }}
            </div>
          </td>
          <td class="text-center row-item py-4">
               <span v-if="props.item.player_confirmations != null" style="font-size: 14px;">
              <span
                v-for="(player, index) in props.item.player_confirmations"
                :key="index"
              >
                {{ player.player_name }},
              </span>
            </span>
            </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleHotelInfoModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
          v-if=" props.item.user_type!='game' "  >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleHotelInfoModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
          v-if="props.item.threshold_hours>=24 && props.item.user_type=='game' "  >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
              <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleMessageModal({
                  show: true,
                })
              "
          v-if="props.item.threshold_hours<24 && props.item.user_type=='game'"  >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
            v-if="props.item.user_type!='game'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
        <template v-slot:footer>
        <!-- Check if all records have the "school" column not null -->
      <v-btn
      color="#38227A"
      dark
      class="text-capitalize px-7 mr-5"
      @click="toggleAssemblyModal1({ show: true, type: 'add' })"
      height="45px"
      style="
        border-radius: 10px;
        margin-left: 15px;
        width: 40%;
        font-size: 20px;
      "
   v-if="allSchoolsNotNull && tabsSelectedValue === 'assembly' && $vuetify.breakpoint.smAndUp">
      <span>Add an Additional Assembly</span>
    </v-btn>
     <v-btn
      color="#38227A"
      dark
      class="text-capitalize px-7 mr-5"
      @click="toggleAssemblyModal1({ show: true, type: 'add' })"
      height="40px"
      style="
        border-radius: 10px;
        margin-left: 15px;
        width: 45%;
        font-size:8px;
      "
   v-if="allSchoolsNotNull && tabsSelectedValue === 'assembly' && $vuetify.breakpoint.xsOnly">
      <span>Add an Additional Assembly</span>
    </v-btn>
      </template>
     <template>
     <tr>
     </tr>
     </template>
    </v-data-table>
    <br />
    <v-btn
      color="#38227A"
      dark
      class="text-capitalize px-7 mr-5"
      @click="toggleHotelInfoModal({ show: true, type: 'add' })"
      height="45px"
      style="
        border-radius: 10px;
        margin-left: 15px;
        width: 40%;
        font-size: 20px;
      "
   v-if="tabsSelectedValue=='hotel' && $vuetify.breakpoint.smAndUp" >
      <span>Add Hotel</span>
    </v-btn>
      <v-btn
      color="#38227A"
      dark
      class="text-capitalize px-7 mr-5"
      @click="toggleHotelInfoModal({ show: true, type: 'add' })"
      height="40px"
      style="
        border-radius: 10px;
        margin-left: 15px;
        width: 40%;
        font-size: 8px;
      "
   v-if="tabsSelectedValue=='hotel' && $vuetify.breakpoint.xsOnly" >
      <span>Add Hotel</span>
    </v-btn>
    <br />
    <br />
    <br />
     <message-modal></message-modal>
    <assembly-schedual-modal1 @reload="getData()"></assembly-schedual-modal1>
    <hotel-info-modal></hotel-info-modal>
    <delete-modal @reload="deleteData()"></delete-modal>
    <ticket-info-table></ticket-info-table>
  </div>
</template>
<script>
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import {
  ASSEMBLY_SCHEDUAL_API_LIST,
  ASSEMBLY_SCHEDUAL_API_DELETE,
  GAME_HOTEL_GET_LIST_API,
  GAME_HOTEL_DELETE_API,
   ADMIN_GAMES_GET_DETAIL,
} from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GameDashboardVASH",
  components: {
    DeleteModal: () => import("../DeleteModal/DeleteModal"),
     MessageModal: () => import("../MessageModal/MessageModal"),
    AssemblySchedualModal1: () =>
      import(
        "../GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal1"
      ),
    HotelInfoModal: () => import("../HotelInfoModal/HotelInfoModal"),
    TicketInfoTable: () => import("../TicketInfo/HostTicketInfo.vue"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,

      AssemblyHeaders: [
        {
          sortable: false,
          text: "School Info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Date/Time",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Point of contact",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],

      hotelHeaders: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Address",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Confirmation Number",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Check in date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Check out date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
 {
          sortable: false,
          text: "Players",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],

      selected: 0,
      tabs: [
        { name: "Assembly Schedule", value: "assembly" },
        { name: "Hotel", value: "hotel" },
      ],
       tab1: [
        { name: "Assembly Schedule", value: "assembly" },
        
      ],
      items: [],
      mailUrls: [],
    };
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
    selectedHeaders() {
      let value = this.tabs[this.selected].value;
      switch (value) {
        case "assembly":
          return {
            header: this.AssemblyHeaders,
            text: "Add Assembly Schedule",
          };
        case "hotel":
          return { header: this.hotelHeaders, text: "Add Hotel" };
        default:
          return { header: this.hotelHeaders, text: "Add Volunteer" };
      }
    },
    tabsSelectedValue() {
    if(this.game_type=='Local')
    {
      return this.tab1[this.selected].value;
      }
      else{
       return this.tabs[this.selected].value;
      }
     
    },
    allSchoolsNotNull() {
      // Check if all records have a non-null "school" column
      return this.items.every(item => item.school !== null);
    },
  },
  watch: {
    selected() {
      this.getData();
    },
  },
  methods: {
    ...mapActions({
      toggleSponsorModal: "gameManagement/toggleSponsor",
      toggleDeleteModal: "deleteManagement/toggleModal",
       toggleMessageModal: "gameManagement/toggleMessageModal",
      toggleAssemblyModal1: "gameManagement/toggleAssembly1",
      toggleVolunteerModal: "gameManagement/toggleVolunteer",
      showToast: "snackBar/showToast",
      toggleHomeTeamModal: "gameManagement/toggleHomeTeam",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      toggleNoteModal: "gameManagement/toggleNoteModal",
      toggleVoucherModal: "gameManagement/toggleVoucherModal",
    }),
    openModal() {
      switch (this.tabsSelectedValue) {
        case "assembly":
          this.toggleAssemblyModal1({ show: true, type: "add" });
          break;
        case "hotel":
          this.toggleHotelInfoModal({ show: true, type: "add" });
          break;
        default:
          break;
      }
    },
     handleAssembly() {
      // Implement the logic for your "Assembly" button click here
     // alert('Assembly button clicked');
    },
    getData() {
      this.items = [];
      switch (this.tabsSelectedValue) {
        case "assembly":
          this.getAssemblyData();
          break;
        case "hotel":
          this.getHotelData();
          break;
      }
    },

    deleteData() {
      switch (this.tabsSelectedValue) {
        case "assembly":
          this.deleteAssemblyData();
          break;
        case "hotel":
          this.deleteHotelData();
          break;
      }
    },
    getHotelData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.hotel_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_HOTEL_GET_LIST_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    getAssemblyData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.assemble_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    deleteAssemblyData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        ASSEMBLY_SCHEDUAL_API_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },

    deleteHotelData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        GAME_HOTEL_DELETE_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    pageReload() {
      location.reload();
    },
    newCampaign() {
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.gamePanel.children.gamehostlist.name,
        query: { type: "add" },
      });
    },
     getGameDetail() {
      const successHandler = (res) => {
        this.game_type = res.data.game_detail.game_type;
       
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] =
        this.$route.query.game_id;
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
  this.getGameDetail();
    this.getData();
  },
};
</script>
<style scoped>
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.tableText {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 25px */

  color: #1d1d1d;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
